import React, { useState, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import AvatarEditor from 'react-avatar-editor'; // Библиотека для обрезки

const succesNotification = () => toast.success('Аватар изменен успешно!');
const errorNotification = () => toast.error('Произошла ошибка при изменении аватара!');
const deleteNotification = () => toast.success('Аватар удален успешно!');
const uploadingNotification = () => toast.loading('Загрузка...');

function UpdateAvatar({ closeModal }) {
  const [avatar, setAvatar] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('none');
  const [scale, setScale] = useState(1); // Масштабирование для обрезки
  const { user, setUser } = useContext(AuthContext);
  const editorRef = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const allowedTypes = ['image/jpeg', 'image/png'];
    if (!allowedTypes.includes(file.type)) {
      toast.error('Неподдерживаемый формат файла! Используйте JPG или PNG.');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error('Размер файла превышает 5MB!');
      return;
    }

    setAvatar(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!avatar) {
      toast.error('Пожалуйста, выберите изображение.');
      return;
    }

    setIsLoading(true);
    uploadingNotification();

    const token = localStorage.getItem('token');
    const formData = new FormData();

    // Получаем обрезанное изображение
    if (editorRef.current) {
      editorRef.current.getImageScaledToCanvas().toBlob((blob) => {
        formData.append('avatar', blob);
        uploadAvatar(token, formData); // Отправляем запрос с обрезанным изображением
      });
    }
  };

  const uploadAvatar = async (token, formData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/update-avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });

      setUser(response.data.user);
      succesNotification();
      closeModal();
    } catch (error) {
      errorNotification();
      console.error('There was an error uploading the avatar!', error);
    } finally {
      setIsLoading(false);
      toast.dismiss();
    }
  };

  const handleDeleteAvatar = async () => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/delete-avatar`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setUser((prevUser) => ({
        ...prevUser,
        avatar: null
      }));
      deleteNotification();
      closeModal();
    } catch (error) {
      errorNotification();
      console.error('There was an error deleting the avatar!', error);
    }
  };

  const handleScaleChange = (e) => {
    setScale(parseFloat(e.target.value));
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  return (
      <Modal isOpen={true} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <i onClick={closeModal} className="fas fa-times text-gray-700 hover:text-violet-400 transition duration-200 absolute top-4 right-4 text-xl cursor-pointer dark:text-white"></i>
        <div className="max-w-md mx-auto bg-white p-6 rounded-lg dark:bg-neutral-800">
          <h2 className="text-3xl font-extrabold text-gray-800 mb-6 dark:text-white">Аватар</h2>

          {/* Текущий аватар */}
          {user.avatar && (
              <div className="mb-4">
                <p className="text-gray-700 font-semibold mb-2 dark:text-white">Текущий аватар:</p>
                <img src={user.avatar} alt="Current Avatar" className="w-32 h-32 rounded-full object-cover" />
              </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2 dark:text-white">Выберите новый аватар:</label>
              <input type="file" accept="image/*" onChange={handleFileChange} className='w-full p-2 border border-gray-300 rounded mb-4 dark:border-neutral-600 dark:bg-neutral-700 dark:text-white'/>
            </div>

            {/* Превью изображения с фильтром */}
            {preview && (
                <div className="mb-4">
                  <p className="text-gray-700 font-semibold mb-2 dark:text-white">Предварительный просмотр:</p>
                  <AvatarEditor
                      ref={editorRef}
                      image={preview}
                      width={150}
                      height={150}
                      border={50}
                      color={[255, 255, 255, 0.6]}
                      scale={scale}
                      rotate={0}
                      className={`rounded-full object-cover filter-${filter}`}
                  />
                </div>
            )}

            {/* Масштабирование */}
            {preview && (
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold mb-2 dark:text-white">Масштаб:</label>
                  <input type="range" min="1" max="2" step="0.01" value={scale} onChange={handleScaleChange} />
                </div>
            )}

            {/* Фильтры */}
            {preview && (
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold mb-2 dark:text-white">Фильтр:</label>
                  <select value={filter} onChange={handleFilterChange} className="w-full p-2 border border-gray-300 rounded mb-4 dark:border-neutral-600 dark:bg-neutral-700 dark:text-white">
                    <option value="none">Без фильтра</option>
                    <option value="grayscale">Черно-белый</option>
                    <option value="sepia">Сепия</option>
                    <option value="brightness">Яркость</option>
                  </select>
                </div>
            )}

            <button type="submit" className="bg-violet-500 hover:bg-violet-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-200" disabled={isLoading}>
              {isLoading ? 'Загрузка...' : 'Сохранить'}
            </button>
          </form>

          {/* Кнопка удаления аватара */}
          <button onClick={handleDeleteAvatar} className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg mt-4 transition duration-200" disabled={isLoading}>
            Удалить аватар
          </button>
        </div>
      </Modal>
  );
}

export default UpdateAvatar;
