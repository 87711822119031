import React from "react";
import { Link } from "react-router-dom";

export default function Groups() {
    return (
        <div className="container mx-auto p-4 dark:text-white">
            <Link to='/' className="cursor-pointer text-gray-700 hover:text-violet-400 transition duration-200 dark:text-white hover:dark:text-violet-400">
              <i className="fas fa-chevron-left mr-2 mb-4"></i>Назад
            </Link>
            <div className="bg-neutral-800 rounded-lg p-8 mb-8">
            <h1 className="text-3xl font-extrabold mb-4">Groups</h1>
            <p>Coming soon</p>
            </div>
        </div>
    );
}