import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import Modal from './Modal';
import EditPostModal from './EditPostModal';
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import SharePostModal from './SharePostModal';
import useWindowSize from "../hooks/useWindowSize";
import DeletePost from "./DeletePost";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { useSwipeable } from 'react-swipeable';
import { motion } from "framer-motion";
import { Pagination } from "swiper/modules";
import './CustomSwiperStyles.css';


const notify = () => toast.error("Произошла ошибка!");

function Post({ post, fetchPosts }) {
    const [comment, setComment] = useState('');
    const [showComments, setShowComments] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [contextMenu, setContextMenu] = useState({ postId: null, x: 0, y: 0 });
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [likes, setLikes] = useState(post.likes);
    const [comments, setComments] = useState(post.comments);
    const [views, setViews] = useState(post.views || 0);
    const contextMenuRef = useRef(null);
    const { width } = useWindowSize();
    const defaultAvatar = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s';
    const hasViewed = useRef(false);
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                setContextMenu({ postId: null, x: 0, y: 0 }); // Закрываем меню
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        const incrementViewCount = async () => {
            if (!hasViewed.current) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/posts/${post.id}/increment-view`, {}, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
                    setViews(response.data.views);
                    hasViewed.current = true;
                } catch (error) {
                    console.error('Error incrementing view count:', error);
                }
            }
        };
        incrementViewCount();

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [post.id]);

    const handleAddComment = async (newComment) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/posts/${post.id}/comment`, {
                content: newComment
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            const createdComment = response.data;
            setComments([...comments, createdComment]);
        } catch (error) {
            console.error('There was an error adding the comment!', error);
        }
    };

    const handleLike = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/posts/${post.id}/like`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setLikes([...likes, { user_id: parseInt(localStorage.getItem('user_id'), 10) }]);
        } catch (error) {
            console.error('There was an error liking the post!', error);
        }
    };

    const handleUnlike = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/posts/${post.id}/like`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setLikes(likes.filter(like => like.user_id !== parseInt(localStorage.getItem('user_id'), 10)));
        } catch (error) {
            console.error('There was an error unliking the post!', error);
        }
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/posts/${post.id}/comment`, {
                content: comment
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            const createdComment = response.data;
            setComment('');
            setComments([...comments, createdComment]);
        } catch (error) {
            console.error('There was an error commenting on the post!', error);
        }
    };

    const toggleComments = () => {
        setShowComments(!showComments);
    };

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
    };

    const closeModal = () => {
        setSelectedImageIndex(null);
    };

    const nextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % post.images.length);
    };

    const prevImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + post.images.length) % post.images.length);
    };

    const handleEditPost = () => {
        setShowEditModal(true);
        hideContextMenu();
    };

    const handleDeletePost = () => {
        setShowDeleteConfirm(true);
        hideContextMenu();
    };

    const deletePost = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/posts/${post.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            fetchPosts();
            setShowDeleteConfirm(false);
        } catch (error) {
            notify();
            console.error('There was an error deleting the post!', error);
        }
    };


    const handleContextMenu = (e, postId) => {

        const x = width < 768 ? (e.touches ? e.touches[0]?.clientX : 0) : e.pageX;
        const y = width < 768 ? (e.touches ? e.touches[0]?.clientY : 0) : e.pageY;

        setContextMenu({ postId, x, y });
    };


    const handleLongPress = (e, postId) => {
        const x = e.touches ? e.touches[0].clientX : 0;
        const y = e.touches ? e.touches[0].clientY : 0;
        setContextMenu({ postId, x, y });
    };


    const hideContextMenu = (e) => {
        setContextMenu({ postId: null, x: 0, y: 0 });
    };

    const handleSharePost = () => {
        setShowShareModal(true);
        hideContextMenu();
    };

    const sendPostToUser = async (userId) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/messages`, {
                user_id: userId,
                content: post.content,
                post_id: post.id,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setShowShareModal(false);
            toast.success('Пост отправлен успешно!');
        } catch (error) {
            console.error('There was an error sending the post!', error);
            toast.error('Произошла ошибка при отправке поста.');
        }
    };

    const formatViews = (count) => {
        if (count < 1000) {
            return count;
        } else if (count < 1000000) {
            return `${(count / 1000).toFixed(1)}K`;
        } else {
            return `${(count / 1000000).toFixed(1)}M`;
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: nextImage,
        onSwipedRight: prevImage,

    });

    const overlayVariants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    };

    const mobileMenuVariants = {
        open: { y: 0, opacity: 1 },
        closed: { y: '100%', opacity: 0 },
    };

    function onClose() {
        hideContextMenu();
    }

    const handleTouchMove = (e) => {
        if (modalRef.current) {
            const touchY = e.touches[0].clientY;
            if (touchY > window.innerHeight * 0.9) {
                onClose();
            }
        }
    };

    return (
        <div className={`bg-white p-5 rounded-lg shadow-md dark:bg-neutral-800 dark:text-neutral-100`}>
            {post.user ? (
                <>
                    <div className="flex items-center mb-4">
                        <img
                            src={post.user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${post.user.avatar}` : defaultAvatar}
                            alt="Avatar"
                            className="w-10 h-10 rounded-full mr-3 object-cover border border-neutral-300 dark:border-neutral-700"
                        />
                        <div>
                            <p className="font-medium">{post.user.name}</p>
                            <p className="text-sm text-neutral-500 dark:text-neutral-400">{format(new Date(post.created_at), 'dd.MM.yyyy HH:mm')}</p>
                        </div>
                        <i
                            className="ml-auto text-xl cursor-pointer text-neutral-500 dark:text-neutral-400 fas fa-ellipsis-v relative"
                            onClick={(e) => handleContextMenu(e, post.id)}
                            onTouchStart={(e) => handleLongPress(e, post.id)} // Долгое нажатие для мобильных устройств
                        ></i>
                        {contextMenu.postId === post.id && width < 768 && (
                            <>
                                <motion.div
                                    className="fixed inset-0 bg-black bg-opacity-30 z-40"
                                    initial="hidden"
                                    animate="visible"
                                    exit="hidden"
                                    variants={overlayVariants}
                                    onClick={hideContextMenu}
                                    onTouchStart={hideContextMenu}
                                    onTouchMove={handleTouchMove}
                                ></motion.div>

                                <motion.div
                                    ref={contextMenuRef}
                                    className="fixed bottom-0 left-0 w-full bg-white shadow-lg rounded-t-2xl dark:bg-neutral-800 z-50"
                                    initial="closed"
                                    animate={contextMenu.postId ? "open" : "closed"}
                                    variants={mobileMenuVariants}
                                    transition={{type: 'spring', stiffness: 300, damping: 30}}
                                    onClick={(e) => e.stopPropagation()}
                                    onTouchStart={(e) => e.stopPropagation()}
                                >
                                    <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mt-2"></div>
                                    <ul className="py-4 space-y-2">
                                        <li className="px-4 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                                            onClick={() => handleSharePost()}>
                                            <i className="fas fa-share mr-2"></i> Поделиться
                                        </li>
                                        <li className="px-4 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer">
                                            <i className="fas fa-flag mr-2"></i> Пожаловаться
                                        </li>
                                        <li className="px-4 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                                            onClick={() => handleEditPost()}>
                                            <i className="fas fa-edit mr-2"></i> Редактировать
                                        </li>
                                        <li className="px-4 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                                            onClick={() => handleDeletePost()}>
                                            <i className="text-red-500 fas fa-trash mr-2"></i> Удалить
                                        </li>
                                    </ul>
                                </motion.div>
                            </>
                        )}

                        {/* Контекстное меню для десктопной версии */}
                        {contextMenu.postId === post.id && width >= 768 && (
                            <div
                                ref={contextMenuRef}
                                className="absolute z-20 bg-white w-44 rounded-lg shadow-lg dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700"
                                style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}
                            >
                                <ul className="py-1">
                                    <li className="px-4 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer" onClick={() => handleSharePost()}>
                                        <i className="text-blue-500 fas fa-share mr-2"></i> Поделиться
                                    </li>
                                    <li className="px-4 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer">
                                        <i className="fas fa-flag mr-2"></i> Пожаловаться
                                    </li>
                                    <li className="px-4 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer" onClick={() => handleEditPost()}>
                                        <i className="fas fa-edit mr-2"></i> Редактировать
                                    </li>
                                    <li className="px-4 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer" onClick={() => handleDeletePost()}>
                                        <i className="text-red-500 fas fa-trash mr-2"></i> Удалить
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <p className="mb-3 text-neutral-800 dark:text-neutral-200">{post.content}</p>
                    {width <= 768 && post.images.length > 0 && (
                        <Swiper
                            pagination={{ clickable: true }}  // Enables dots
                            modules={[Pagination]}  // Include Pagination module
                            className="mySwiper rounded-lg mb-4"
                            {...swipeHandlers}
                        >
                            {post.images.map((image, index) => (
                                <SwiperSlide key={index} onClick={() => handleImageClick(index)}>
                                    <img
                                        src={`${process.env.REACT_APP_BASE_URL}/storage/${image.image_path}`}
                                        alt={`Slide ${index}`}
                                        className="w-full h-full object-cover rounded-lg"
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}

                    {post.images.length > 0 && width > 768 && (
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 mb-4">
                            {post.images.map((image, index) => (
                                <img
                                    key={image.id}
                                    src={`${process.env.REACT_APP_BASE_URL}/storage/${image.image_path}`}
                                    alt="Post"
                                    className="w-full h-36 object-cover rounded-lg cursor-pointer"
                                    onClick={() => handleImageClick(index)}
                                />
                            ))}
                        </div>
                    )}
                    <div className="flex items-center">
                        <div className="flex space-x-2">
                            <div
                                className="flex items-center bg-neutral-100 border border-neutral-200 text-neutral-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100 px-3 py-1.5 rounded-full">
                                {likes.some(like => like.user_id === parseInt(localStorage.getItem('user_id'), 10)) ? (
                                    <button onClick={handleUnlike} className="text-red-500">
                                        <i className="fas fa-heart text-xl"></i>
                                    </button>
                                ) : (
                                    <button onClick={handleLike} className="text-neutral-500 dark:text-neutral-400 ">
                                        <i className="far fa-heart text-xl"></i>
                                    </button>
                                )}
                                <span className="text-sm font-medium ml-2">{likes.length}</span>
                            </div>
                            <div
                                className="flex items-center bg-neutral-100 border border-neutral-200 text-neutral-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100 px-3 py-1.5 rounded-full">
                                <button onClick={toggleComments} className="text-neutral-500 dark:text-neutral-400">
                                    <i className="far fa-comment text-xl"></i>
                                </button>
                                <span className="text-sm font-medium ml-2">{comments.length}</span>
                            </div>
                            <div
                                className="flex items-center bg-neutral-100 border border-neutral-200 text-neutral-600 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100 px-4 py-1.5 rounded-full">
                                <button className="text-neutral-500 dark:text-neutral-400" onClick={handleSharePost}>
                                    <i className="fas fa-share text-xl"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center text-neutral-500 dark:text-neutral-400 ml-auto">
                            <i className="mr-2 fas fa-eye text-sm"></i>
                            <span className="text-sm font-medium">
                                {formatViews(post.views)}
                            </span>
                        </div>
                    </div>

                    {showComments && (
                        <div className="space-y-4">
                            <h3 className="text-lg font-medium">Комментарии</h3>
                            {comments.map(comment => (
                                <div key={comment.id} className="flex items-start space-x-2">
                                    <img
                                        src={comment.user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${comment.user.avatar}` : defaultAvatar}
                                        alt="Avatar"
                                        className="w-8 h-8 rounded-full object-cover"
                                    />
                                    <div className="flex-1 bg-neutral-100 dark:bg-neutral-800 p-2 rounded-lg">
                                        <p className="font-medium">{comment.user.name}</p>
                                        <p className="text-sm">{comment.content}</p>
                                        <p className="text-xs text-neutral-500 dark:text-neutral-400">{format(new Date(comment.created_at), 'dd MMM yyyy HH:mm')}</p>
                                    </div>
                                </div>
                            ))}
                            <form onSubmit={handleCommentSubmit} className="flex items-center mt-4 space-x-2">
                                <input
                                    value={comment}
                                    onChange={handleCommentChange}
                                    className="flex-grow w-full border border-neutral-300 p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-neutral-700 dark:text-neutral-100 dark:border-neutral-600"
                                    placeholder="Комментарий..."
                                />
                                <button type="submit" className="bg-violet-600 text-white px-2.5 py-1.5 rounded-full">
                                    <i className="fas fa-paper-plane"></i>
                                </button>
                            </form>
                        </div>
                    )}
                    {selectedImageIndex !== null && (
                        <Modal
                            images={post.images}
                            selectedIndex={selectedImageIndex}
                            onClose={closeModal}
                            nextImage={nextImage}
                            prevImage={prevImage}
                            post={post}
                            handleUnlike={handleUnlike}
                            handleLike={handleLike}
                            handleAddComment={handleAddComment}
                            onShare={handleSharePost}
                        />
                    )}
                    {showEditModal && (
                        <EditPostModal
                            post={post}
                            onClose={() => setShowEditModal(false)}
                            onSave={fetchPosts}
                        />
                    )}
                    {showDeleteConfirm && (
                        <DeletePost
                            show={showDeleteConfirm}
                            onClose={() => setShowDeleteConfirm(false)}
                            onDelete={deletePost}
                        />
                    )}
                    {showShareModal && (
                        <SharePostModal
                            post={post}
                            onClose={() => setShowShareModal(false)}
                            onSend={sendPostToUser}
                        />
                    )}
                </>
            ) : (
                <Skeleton count={3}/>
            )}
        </div>
    );
}

export default Post;
