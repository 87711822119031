import React, { useState, useEffect } from 'react';
import Pusher from 'pusher-js';

const UserStatus = ({ userId, initialStatus, initialLastSeen }) => {
    const [isOnline, setIsOnline] = useState(initialStatus);
    const [lastSeen, setLastSeen] = useState(initialLastSeen);

    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
            cluster: 'eu',
            encrypted: true,
        });

        const channel = pusher.subscribe('users');
        channel.bind('UserOnlineStatus', (data) => {
            if (data.user_id === userId) {
                setIsOnline(data.is_online);
                setLastSeen(data.last_activity);
            }
        });

        return () => {
            channel.unbind_all();
            pusher.unsubscribe('users');
        };
    }, [userId]);

    const formatLastSeen = (datetime) => {
        if (!datetime) return '';
        const date = new Date(datetime);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    return (
        <div className="flex items-center text-gray-500 dark:text-white text-sm ">
            <i className={`fas fa-circle ${isOnline ? 'text-green-500' : 'text-red-500'} mr-2 text-[10px] w-2 h-2`} />
            {isOnline ? 'онлайн' : `оффлайн (последний раз был в сети: ${formatLastSeen(lastSeen)})`}
        </div>
    );
};

export default UserStatus;
