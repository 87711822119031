import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pusher from 'pusher-js';

const OnlineStatus = ({ userId, initialStatus, initialLastActivity }) => {
    const [isOnline, setIsOnline] = useState(initialStatus);
    const [lastActivity, setLastSeen] = useState(initialLastActivity);

    const fetchOnlineStatus = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/update-status`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
            setIsOnline(response.data.is_online);
            setLastSeen(response.data.last_activity);
        } catch (error) {
            console.error('Ошибка при получении статуса пользователя!', error);
        }
    };

    useEffect(() => {
        fetchOnlineStatus();
    }, []);

    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
            cluster: 'eu',
            encrypted: true,
        });

        const channel = pusher.subscribe('users');
        channel.bind('UserOnlineStatus', (data) => {
            if (data.user_id === userId) {
                setIsOnline(data.is_online);
                setLastSeen(data.last_activity);
            }
        });

        return () => {
            channel.unbind_all();
            pusher.unsubscribe('users');
        };
    }, [userId]);

    const formatLastSeen = (datetime) => {
        const date = new Date(datetime);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    return (
        <div className="text-gray-500 dark:text-white text-sm mx-auto">
            {isOnline ? (
                <p>
                    <i className="fas fa-circle text-green-500"></i> онлайн
                </p>
            ) : (
                <p>
                    <i className="fas fa-circle text-red-500"></i> оффлайн
                    <br />
                    Последний раз был в сети: {formatLastSeen(lastActivity)}
                </p>
            )}
        </div>
    );
};

export default OnlineStatus;
