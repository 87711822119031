import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import ClipLoader from 'react-spinners/ClipLoader';

const categories = [
    { id: 'bug', name: 'Баг', icon: 'fas fa-bug', color: 'from-red-400 to-red-600' },
    { id: 'feature_request', name: 'Запрос на функцию', icon: 'fas fa-lightbulb', color: 'from-green-400 to-green-600' },
    { id: 'ui_issue', name: 'Проблема UI', icon: 'fas fa-paint-brush', color: 'from-blue-400 to-blue-600' },
    { id: 'performance', name: 'Производительность', icon: 'fas fa-tachometer-alt', color: 'from-yellow-400 to-yellow-600' },
    { id: 'security', name: 'Безопасность', icon: 'fas fa-shield-alt', color: 'from-purple-400 to-purple-600' },
    { id: 'documentation', name: 'Документация', icon: 'fas fa-book', color: 'from-teal-400 to-teal-600' },
    { id: 'accessibility', name: 'Доступность', icon: 'fas fa-universal-access', color: 'from-pink-400 to-pink-600' },
    { id: 'compatibility', name: 'Совместимость', icon: 'fas fa-sync-alt', color: 'from-orange-400 to-orange-600' },
    { id: 'other', name: 'Другое', icon: 'fas fa-question-circle', color: 'from-gray-400 to-gray-600' },
];

export default function Report() {
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!category || !description || !email) {
            toast.error('Пожалуйста, заполните все поля.');
            return;
        }

        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error('Токен не найден. Пожалуйста, войдите в систему.');
                return;
            }

            await axios.post(`${process.env.REACT_APP_API_URL}/report`, {
                category,
                description,
                email,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            toast.success('Ваш отчет успешно отправлен!');
            navigate('/settings');
            setCategory('');
            setDescription('');
            setEmail('');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('Неавторизованный доступ! Пожалуйста, войдите в систему.');
            } else {
                toast.error('Произошла ошибка при отправке отчета.');
            }
            console.error('Ошибка при отправке репорта:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto p-4 h-screen">
            <Link to="/settings" className="text-gray-700 mb-4 hover:text-violet-400 transition duration-200 dark:text-white dark:hover:text-violet-400">
                <i className="fas fa-chevron-left mr-2"></i>Назад
            </Link>
            <h1 className="text-3xl font-extrabold mb-4 mt-4 dark:text-white">Сообщить о проблеме</h1>

            <p className="text-lg dark:text-white mb-2">Категория:</p>
            <div className="flex items-center justify-center space-x-4 overflow-x-auto mb-8 py-2 scrollbar-hide scroll-smooth">
                {categories.map((cat) => (
                    <div
                        key={cat.id}
                        className={`flex flex-shrink-0 h-24 w-24 flex-col items-center justify-center p-4 mb-8 rounded-2xl cursor-pointer
                        transition-transform transform 
                        bg-gradient-to-r ${cat.color} text-white min-w-fit
                        ${category === cat.id ? 'scale-110 shadow-2xl z-50 shadow-violet-500' : ''}`}
                        onClick={() => setCategory(cat.id)} style={{
                        boxShadow: category === cat.id
                            ? '0 10px 25px rgba(138, 43, 226, 0.5)'  // Глубокая фиолетовая тень
                            : '0 10px 20px rgba(0, 0, 0, 0.1)', // Обычная мягкая тень
                    }}
                    >
                        <i className={`${cat.icon} text-3xl mb-2`}></i>
                        <p className="font-semibold text-center">{cat.name}</p>
                    </div>
                ))}
            </div>

            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 dark:text-white mb-2">Описание проблемы:</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:border-indigo-500 transition duration-300 dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                        rows="4"
                        placeholder="Опишите вашу проблему или предложение"
                    ></textarea>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 dark:text-white mb-2">Ваш Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:border-indigo-500 transition duration-300 dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                        placeholder="Ваш email для обратной связи"
                    />
                </div>
                <button
                    type="submit"
                    className="bg-violet-500 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-violet-600 transition duration-300 flex items-center justify-center"
                    disabled={!category || !description || !email || loading}
                >
                    {loading && <ClipLoader color="#ffffff" size={20} className="mr-2" />}
                    Отправить отчет
                </button>
            </form>
        </div>
    );
}
