import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import useWindowSize from "../hooks/useWindowSize";
import {show} from "react-modal/lib/helpers/ariaAppHider";

const notifyError = () => toast.error('Произошла ошибка при изменении поста!');

function EditPostModal({ post, onClose, onSave }) {
    const { width } = useWindowSize();
    const [content, setContent] = useState(post.content);

    const handleSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/posts/${post.id}`, {
                content: content
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            onSave();
            onClose();
        } catch (error) {
            notifyError();
            console.error('There was an error saving the post!', error);
        }
    };

    const handleTouchMove = (e) => {
        if (e.touches[0].clientY > window.innerHeight * 0.9) {
            onClose();
        }
    };

    if (!show) return null;

    const overlayVariants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 }
    };

    const mobileModalVariants = {
        open: { y: 0, opacity: 1 },
        closed: { y: '100%', opacity: 0 }
    };

    const desktopModalVariants = {
        open: { scale: 1, opacity: 1 },
        closed: { scale: 0.9, opacity: 0 }
    };

    return (
        <>
            {/* Оверлей для мобильной версии */}
            {width < 768 && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-30 z-40"
                    initial="hidden"
                    animate={show ? "visible" : "hidden"}
                    variants={overlayVariants}
                    transition={{ duration: 0.3 }}
                    onClick={onClose}
                ></motion.div>
            )}

            {/* Модальное окно для мобильной версии */}
            {width < 768 ? (
                <motion.div
                    className={`fixed bottom-0 left-0 w-full h-auto bg-white shadow-lg rounded-t-2xl dark:bg-neutral-800 z-50`}
                    initial="closed"
                    animate={show ? "open" : "closed"}
                    variants={mobileModalVariants}
                    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                    onTouchMove={handleTouchMove}
                >
                    <div className="p-5">
                        <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4"></div>

                        <h2 className="text-lg font-semibold mb-4 dark:text-white">Редактировать пост</h2>
                        <textarea
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            className="w-full border border-gray-300 p-2 rounded-md dark:bg-neutral-700 dark:text-white dark:border-neutral-600"
                            rows="6"
                        />
                        <div className="flex justify-end mt-4 space-x-2">
                            <button
                                onClick={onClose}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 dark:bg-neutral-600 dark:hover:bg-neutral-700 transition duration-300"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={handleSave}
                                className="bg-violet-500 text-white px-4 py-2 rounded-md hover:bg-violet-600 dark:bg-violet-600 dark:hover:bg-violet-700 transition duration-300"
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </motion.div>
            ) : (
                // Модальное окно для десктопной версии
                <motion.div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                    initial="closed"
                    animate={show ? "open" : "closed"}
                    variants={desktopModalVariants}
                    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                >
                    <motion.div
                        className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg md:max-w-xl dark:bg-neutral-800"
                        variants={desktopModalVariants}
                        transition={{ duration: 0.3 }}
                    >
                        <h2 className="text-lg font-semibold mb-4 dark:text-white">Редактировать пост</h2>
                        <textarea
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            className="w-full border border-gray-300 p-2 rounded-md dark:bg-neutral-700 dark:text-white dark:border-neutral-600"
                            rows="6"
                        />
                        <div className="flex justify-end mt-4 space-x-2">
                            <button
                                onClick={onClose}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 dark:bg-neutral-600 dark:hover:bg-neutral-700 transition duration-300"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={handleSave}
                                className="bg-violet-500 text-white px-4 py-2 rounded-md hover:bg-violet-600 dark:bg-violet-600 dark:hover:bg-violet-700 transition duration-300"
                            >
                                Сохранить
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </>
    );
}

export default EditPostModal;
