import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Post from './Post';
import { ClipLoader } from 'react-spinners';

function PostList({ posts = [], setPosts, userId, userPreferences = {} }) {
  const [loading, setLoading] = useState(true);

  const getCachedViews = () => {
    const cachedViews = localStorage.getItem('postViews');
    return cachedViews ? JSON.parse(cachedViews) : {};
  };

  const cacheViews = (postId, views) => {
    const cachedViews = getCachedViews();
    cachedViews[postId] = views;
    localStorage.setItem('postViews', JSON.stringify(cachedViews));
  };

  const syncViewsWithServer = async () => {
    const cachedViews = getCachedViews();
    const postIds = Object.keys(cachedViews);

    try {
      await Promise.all(
          postIds.map(async (postId) => {
            const views = cachedViews[postId];
            await axios.post(`${process.env.REACT_APP_API_URL}/posts/${postId}/sync-views`, { views }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            });
          })
      );

      localStorage.removeItem('postViews');
    } catch (error) {
      console.error('Error syncing views with the server', error);
    }
  };

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params: {
          userId,
        },
      });

      if (Array.isArray(response.data)) {
        const cachedViews = getCachedViews();

        const postsWithViews = await Promise.all(
            response.data.map(async (post) => {
              let views = cachedViews[post.id] || post.views;
              if (!cachedViews[post.id]) {
                cacheViews(post.id, views);
              }

              const postAgeInHours = (Date.now() - new Date(post.createdAt).getTime()) / 1000 / 3600;
              return { ...post, views, postAgeInHours };
            })
        );

        const recommendedPosts = postsWithViews.sort((a, b) => {
          const aCategoryWeight = userPreferences?.categories?.includes(a.category) ? 1 : 0;
          const bCategoryWeight = userPreferences?.categories?.includes(b.category) ? 1 : 0;

          const aScore =
              (a.views * 0.4) +
              (a.likes.length * 0.3) +
              (a.comments.length * 0.2) +
              (aCategoryWeight * 0.5) -
              (a.postAgeInHours * 0.05);

          const bScore =
              (b.views * 0.4) +
              (b.likes.length * 0.3) +
              (b.comments.length * 0.2) +
              (bCategoryWeight * 0.5) -
              (b.postAgeInHours * 0.05);

          return bScore - aScore;
        });

        setPosts(recommendedPosts);
      } else {
        console.error('Unexpected response data format:', response.data);
      }
    } catch (error) {
      console.error('There was an error fetching the posts!', error);
    } finally {
      setLoading(false);
    }
  };

  const editPost = async (postId, updatedData) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/posts/${postId}`, updatedData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      setPosts((prevPosts) =>
          prevPosts.map((post) =>
              post.id === postId ? { ...post, ...response.data } : post
          )
      );
    } catch (error) {
      console.error('Error editing the post:', error);
    }
  };

  const deletePost = async (postId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/posts/${postId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
    } catch (error) {
      console.error('Error deleting the post:', error);
    }
  };

  useEffect(() => {
    fetchPosts();
    const syncInterval = setInterval(syncViewsWithServer, 300000);
    return () => clearInterval(syncInterval);
  }, [userId]);

  if (loading) {
    return (
        <div className="flex items-center justify-center min-h-screen">
          <ClipLoader color="#6B46C1" loading={loading} size={75} />
        </div>
    );
  }

  if (posts.length === 0) {
    return (
        <div className="container mx-auto rounded-lg min-h-screen dark:bg-neutral-900 p-4">
          <p className="text-center text-gray-700 dark:text-white">Нет постов для отображения.</p>
        </div>
    );
  }

  return (
      <div className="container mx-auto rounded-lg min-h-screen dark:bg-neutral-900 last:mb-20">
        <div className="space-y-6">
          {posts.map(post => (
              <Post key={post.id} post={post} editPost={editPost} deletePost={deletePost} />
          ))}
        </div>
      </div>
  );
}

export default PostList;
