import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Loader = () => {
    return (
        <div
            className="flex items-center p-4 bg-gray-100 shadow-inner mb-4 rounded-lg dark:bg-neutral-700 dark:text-white">
            <Skeleton circle={true} height={40} width={40} className="mr-4 bg-gray-300 dark:bg-neutral-600"/>
            <div className="flex-1 space-y-2">
                <Skeleton width="75%" height={20}/>
                <Skeleton width="40%" height={15}/>
            </div>
            <Skeleton width={12} height={24}/>
        </div>
    )
}

export default Loader;