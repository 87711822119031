import React from 'react';
import UpdateAvatar from './UpdateAvatar';

function ProfileAvatar({ user, showModal, setShowModal }) {
    const defaultAvatar = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s';
    const avatarSrc = user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${user.avatar}` : defaultAvatar;

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (
        <div className="flex flex-col items-center mb-8">
            {showModal && <UpdateAvatar user={user} closeModal={closeModal} />}
            <img
                src={avatarSrc}
                alt="Аватар"
                className="w-32 h-32 object-cover rounded-full shadow-lg border-2 border-gray-300 dark:border-gray-600 mb-4"
            />
            <button
                onClick={openModal}
                className="bg-violet-500 hover:bg-violet-600 text-white font-medium px-4 py-2 rounded-lg shadow-md transition-transform"
            >
                <i className="fas fa-camera"></i> Изменить
            </button>
        </div>
    );
}

export default ProfileAvatar;
