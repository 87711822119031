import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import axios from 'axios';
import Pusher from 'pusher-js';
import BottomNav from "./BottomNav";
import useWindowSize from "../hooks/useWindowSize";

function Aside() {
    const { isLoggedIn } = useContext(AuthContext);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const { width } = useWindowSize();
    const [ friendRequestsCount, setFriendRequestsCount ] = useState(0);


    useEffect(() => {
        if (isLoggedIn) {
            const fetchUnreadMessages = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages/unread_count`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    setUnreadMessagesCount(response.data.unread_count || 0);
                } catch (error) {
                    console.error('Error fetching unread messages count', error);
                }
            };

            fetchUnreadMessages();




            const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
                cluster: 'eu',
                auth: {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            });

            const channel = pusher.subscribe(`private-user-${localStorage.getItem('user_id')}`);

            channel.bind('new-message', (data) => {
                setUnreadMessagesCount((prevCount) => prevCount + 1);
            });

            return () => {
                pusher.unsubscribe(`private-user-${localStorage.getItem('user_id')}`);
            };
        }
    }, [isLoggedIn]);


    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    if (width < 768) {
        return <BottomNav unreadMessagesCount={unreadMessagesCount} />;
    }

    return (
        <aside className="w-64 p-6 sticky top-0 h-screen overflow-y-auto overflow-x-hidden flex-shrink-0 flex flex-col">
            <ul className="space-y-4">
                <li>
                    <Link to="/profile" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fa-regular fa-user mr-3 text-xl"></i>
                        <span className="font-semibold">Профиль</span>
                    </Link>
                </li>
                <li>
                    <Link to="/messages"
                          className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fa-regular fa-message mr-3 text-xl"></i>
                        <div className="flex items-center justify-between w-full">
                            <span className="font-semibold">Сообщения</span>
                            {unreadMessagesCount > 0 && (
                                <span
                                    className="bg-red-500 text-white text-xs px-2 py-1 items-center rounded-full">
                                {unreadMessagesCount}
                            </span>
                            )}
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/posts/all"
                          className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fas fa-list mr-3 text-xl"></i>
                        <span className="font-semibold">Посты</span>
                    </Link>
                </li>
                <li>
                    <Link to="/friends" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fas fa-user-group mr-3 text-xl"></i>
                        <span className="font-semibold">Друзья</span>
                    </Link>
                </li>
                <li>
                    <Link to="/notifications" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fa-regular fa-bell mr-3 text-xl"></i>
                        <span className="font-semibold">Уведомления</span>
                    </Link>
                </li>
                <li>
                    <Link to="/search" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fas fa-search mr-3 text-xl"></i>
                        <span className="font-semibold">Поиск</span>
                    </Link>
                </li>
                <li>
                    <Link to="/groups" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fa-regular fa-compass mr-3 text-xl"></i>
                        <span className="font-semibold">Группы</span>
                    </Link>
                </li>
                <li>
                    <Link to="/games" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fas fa-music mr-3 text-xl"></i>
                        <span className="font-semibold">Музыка</span>
                    </Link>
                </li>
                <li>
                    <Link to="/settings" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fas fa-cog mr-3 text-xl"></i>
                        <span className="font-semibold">Настройки</span>
                    </Link>
                </li>
                {isLoggedIn && (
                    <li>
                        <button onClick={handleLogout} className="flex items-center text-red-600 hover:text-red-800 transition duration-300">
                            <i className="fas fa-sign-out-alt mr-3 text-xl"></i>
                            <span className="font-semibold">Выйти</span>
                        </button>
                    </li>
                )}
            </ul>
        </aside>
    );
}

export default Aside;
