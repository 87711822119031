import React from 'react';
import FriendListForProfile from './FriendListForProfile';
import OnlineStatus from "../OnlineStatus";

function ProfileInfo({ user }) {
    return (
        <div className="text-center space-y-6">
            <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                {user.name}
            </p>
            <OnlineStatus userId={user.id} initialStatus={user.is_online} initialLastSeen={user.last_activity} />
            <p className="text-gray-800 dark:text-gray-400 text-sm flex items-center justify-center gap-2">
                <i className="fas fa-envelope"></i>{user.email}
            </p>
            <FriendListForProfile />
        </div>
    );
}

export default ProfileInfo;
