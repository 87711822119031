import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Post from './Post';
import { ClipLoader } from 'react-spinners';
import {Link} from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";

function PostList2({ posts = [], setPosts, userId, userPreferences = {} }) {
    const [loading, setLoading] = useState(true);
    const { width} = useWindowSize();

    // Function to get cached views from localStorage
    const getCachedViews = () => {
        const cachedViews = localStorage.getItem('postViews');
        return cachedViews ? JSON.parse(cachedViews) : {};
    };

    // Function to cache views in localStorage
    const cacheViews = (postId, views) => {
        const cachedViews = getCachedViews();
        cachedViews[postId] = views;
        localStorage.setItem('postViews', JSON.stringify(cachedViews));
    };

    // Sync cached views with the server periodically
    const syncViewsWithServer = async () => {
        const cachedViews = getCachedViews();
        const postIds = Object.keys(cachedViews);

        try {
            await Promise.all(
                postIds.map(async (postId) => {
                    const views = cachedViews[postId];
                    await axios.post(`${process.env.REACT_APP_API_URL}/posts/${postId}/sync-views`, { views }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
                })
            );

            // Clear cache after successful sync
            localStorage.removeItem('postViews');
        } catch (error) {
            console.error('Error syncing views with the server', error);
        }
    };

    // Fetch posts and apply recommendation algorithm with cached views
    const fetchPosts = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                params: {
                    userId,
                },
            });

            if (Array.isArray(response.data)) {
                const cachedViews = getCachedViews();

                const postsWithViews = await Promise.all(
                    response.data.map(async (post) => {
                        let views = cachedViews[post.id] || post.views;
                        // Cache views if not already cached
                        if (!cachedViews[post.id]) {
                            cacheViews(post.id, views);
                        }

                        const postAgeInHours = (Date.now() - new Date(post.createdAt).getTime()) / 1000 / 3600;
                        return { ...post, views, postAgeInHours };
                    })
                );

                const recommendedPosts = postsWithViews.sort((a, b) => {
                    const aCategoryWeight = userPreferences?.categories?.includes(a.category) ? 1 : 0;
                    const bCategoryWeight = userPreferences?.categories?.includes(b.category) ? 1 : 0;

                    const aScore =
                        (a.views * 0.4) +
                        (a.likes.length * 0.3) +
                        (a.comments.length * 0.2) +
                        (aCategoryWeight * 0.5) -
                        (a.postAgeInHours * 0.05);

                    const bScore =
                        (b.views * 0.4) +
                        (b.likes.length * 0.3) +
                        (b.comments.length * 0.2) +
                        (bCategoryWeight * 0.5) -
                        (b.postAgeInHours * 0.05);

                    return bScore - aScore;
                });

                setPosts(recommendedPosts);
            } else {
                console.error('Unexpected response data format:', response.data);
            }
        } catch (error) {
            console.error('There was an error fetching the posts!', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPosts();
        // Sync cached views with the server every 5 minutes
        const syncInterval = setInterval(syncViewsWithServer, 300000); // 300000 ms = 5 min
        return () => clearInterval(syncInterval);
    }, [userId]);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <ClipLoader color="#6B46C1" loading={loading} size={75} />
            </div>
        );
    }

    if (posts.length === 0) {
        return (
            <div className="container mx-auto rounded-lg min-h-screen dark:bg-neutral-900 p-4">
                <p className="text-center text-gray-700 dark:text-white">Нет постов для отображения.</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto rounded-lg min-h-screen dark:bg-neutral-900 last:mb-20">
            <div className="p-4 space-y-4">
                <div className="space-y-2">
                <Link
                    to="/"
                    className={`cursor-pointer text-gray-700 hover:text-violet-500 transition duration-200 dark:text-white hover:dark:text-violet-400 ${width < 768 ? 'hidden' : ''}`}                    >
                    <i className="fas fa-chevron-left mr-2 mt-4"></i>Назад
                </Link>
                <h2 className="text-3xl font-extrabold text-gray-800 mb-5 dark:text-white">
                    Посты
                </h2>
                </div>
                {posts.map(post => (
                    <Post key={post.id} post={post}/>
                ))}
            </div>
        </div>
    );
}

export default PostList2;
