import React, { useState } from 'react';
import axios from 'axios';
import { ClipLoader } from "react-spinners";

function CreatePost({ addPost, closeModal }) {
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);

    const previewUrls = files.map(file => URL.createObjectURL(file));
    setPreviewImages(previewUrls);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formData = new FormData();

    if (!content && images.length === 0) {
      setErrorMessage('Нужно добавить текст или хотя бы одно изображение.');
      return;
    }

    if (content) {
      formData.append('content', content);
    }

    images.forEach(image => {
      formData.append('images[]', image);
    });

    setIsLoadingPost(true);
    setIsUploadingImages(true);
    setErrorMessage('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/posts`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      const newPost = response.data;
      addPost(newPost);
      setContent('');
      setImages([]);
      setPreviewImages([]);
      setUploadProgress(0);
      closeModal();
    } catch (error) {
      console.error('Ошибка при создании поста!', error);
      setErrorMessage('Произошла ошибка при создании поста. Пожалуйста, попробуйте снова.');
    } finally {
      setIsLoadingPost(false);
      setIsUploadingImages(false);
    }
  };

  return (
      <form onSubmit={handleSubmit} className="space-y-6">
      <textarea
          value={content}
          onChange={handleContentChange}
          className="w-full border border-gray-300 rounded-lg p-3 bg-gray-100 text-gray-800 dark:bg-neutral-700 dark:border-neutral-600 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-violet-500"
          placeholder="Напишите что-нибудь (необязательно)"
          rows="4"
      />
        <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageChange}
            className="block w-full text-sm text-gray-700 dark:text-white file:border-none file:rounded-md file:bg-violet-100 file:text-violet-700 file:transition-colors file:duration-300 file:ease-in-out file:hover:bg-violet-200 dark:file:bg-neutral-600 dark:file:text-white dark:file:hover:bg-neutral-700"
        />
        {previewImages.length > 0 && (
            <div className="grid grid-cols-3 gap-3">
              {previewImages.map((url, index) => (
                  <div key={index} className="relative transition-transform transform hover:scale-100 duration-300 ease-in-out">
                    <img
                        src={url}
                        alt={`Preview ${index}`}
                        className="w-full h-32 object-cover rounded-lg shadow-md transition-transform duration-300 ease-in-out"
                    />
                  </div>
              ))}
            </div>
        )}

        {isUploadingImages && (
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-3">
              <div
                  className="bg-violet-500 h-2.5 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
              />
              <p className="text-sm text-gray-500 mt-1">Загрузка: {uploadProgress}%</p>
            </div>
        )}

        {errorMessage && (
            <div className="text-red-500 text-center mt-2">
              {errorMessage}
            </div>
        )}

        {isLoadingPost ? (
            <button
                type="button"
                disabled
                className="w-full bg-gray-400 text-white py-3 rounded-full"
            >
              <ClipLoader color="white" loading={isLoadingPost} size={20} />
            </button>
        ) : (
            <button
                type="submit"
                className="w-full bg-violet-500 text-white py-3 rounded-full transition-colors duration-300 ease-in-out hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 dark:bg-violet-500 dark:hover:bg-violet-600"
            >
              <i className="fas fa-paper-plane mr-2"></i>
              Опубликовать
            </button>
        )}
      </form>
  );
}

export default CreatePost;
