import React, { useState } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import useWindowSize from '../hooks/useWindowSize';

function Modal({ images, selectedIndex, onClose, onShare, nextImage, prevImage, post, handleUnlike, handleLike, handleAddComment }) {
    const [localPost, setLocalPost] = useState({
        ...post,
        likes: post.likes || [],
        comments: post.comments || [],
    });
    const [newComment, setNewComment] = useState('');
    const [isCommentsOpen, setIsCommentsOpen] = useState(false); // Для отображения комментариев
    const { width } = useWindowSize();

    const defaultAvatar = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s';

    const onCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const onSubmitComment = async () => {
        if (newComment.trim()) {
            try {
                const updatedComment = await handleAddComment(newComment);
                if (updatedComment) {
                    setLocalPost(prevPost => ({
                        ...prevPost,
                        comments: [...prevPost.comments, updatedComment]
                    }));
                    setNewComment('');
                }
            } catch (error) {
                console.error('Ошибка при добавлении комментария:', error);
            }
        }
    };

    const onLike = async () => {
        try {
            const updatedLikes = await handleLike(post.id);
            if (updatedLikes) {
                setLocalPost(prevPost => ({
                    ...prevPost,
                    likes: updatedLikes,
                }));
            }
        } catch (error) {
            console.error('Ошибка при добавлении лайка:', error);
        }
    };

    const onUnlike = async () => {
        try {
            const updatedLikes = await handleUnlike(post.id);
            if (updatedLikes) {
                setLocalPost(prevPost => ({
                    ...prevPost,
                    likes: updatedLikes,
                }));
            }
        } catch (error) {
            console.error('Ошибка при удалении лайка:', error);
        }
    };

    const toggleComments = () => {
        setIsCommentsOpen(!isCommentsOpen);
    };

    const isMobile = width < 768; // Проверка мобильного вида

    if (isMobile) {
        return (
            <div className="fixed inset-0 z-50 bg-black bg-opacity-80 flex flex-col justify-between">
                <button onClick={onClose} className="absolute top-4 right-4 text-white text-2xl z-50">
                    <i className="fa-solid fa-xmark"></i>
                </button>

                {/* Изображение */}
                <div className="relative w-full flex-grow flex justify-center items-center">
                    <button onClick={prevImage} className="absolute left-0 text-white text-2xl p-4">
                        <i className="fa-solid fa-chevron-left"></i>
                    </button>
                    <img
                        src={`${process.env.REACT_APP_BASE_URL}/storage/${images[selectedIndex].image_path}`}
                        alt="Full Size"
                        className="max-h-[75vh] w-auto object-contain"
                    />
                    <button onClick={nextImage} className="absolute right-0 text-white text-2xl p-4">
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </div>

                {/* Кнопки действий */}
                <div className="bg-black bg-opacity-80 p-4 flex justify-between items-center text-white">
                    <div className="flex items-center">
                        <img
                            src={localPost.user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${localPost.user.avatar}` : defaultAvatar}
                            alt="Profile"
                            className="w-8 h-8 rounded-full mr-2"
                        />
                        <div>
                            <p className="text-sm font-semibold">{localPost.user.name}</p>
                            <p className="text-xs text-gray-300">{format(new Date(localPost.created_at), 'dd MMMM, yyyy', { locale: ru })}</p>
                        </div>
                    </div>
                    <div className="flex space-x-4">
                        <button onClick={localPost.likes.some(like => like.user_id === parseInt(localStorage.getItem('user_id'), 10)) ? onUnlike : onLike}>
                            <i className={`fa-heart ${localPost.likes.some(like => like.user_id === parseInt(localStorage.getItem('user_id'), 10)) ? 'fas text-red-500' : 'far text-white'} text-2xl`}></i>
                        </button>
                        <button onClick={toggleComments}>
                            <i className="fa-solid fa-comment text-2xl"></i>
                        </button>
                        <button onClick={onShare}>
                            <i className="fa-solid fa-share text-2xl"></i>
                        </button>
                    </div>
                </div>

                {/* Модалка комментариев */}
                {isCommentsOpen && (
                    <div className="absolute bottom-0 left-0 right-0 h-2/3 bg-white p-4 rounded-t-lg dark:bg-neutral-800">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold ">Комментарии</h2>
                            <button onClick={toggleComments} className="text-white dark:text-neutral-200">
                                <i className="fa-solid fa-times text-xl"></i>
                            </button>
                        </div>
                        <div className="flex flex-col h-[calc(100%-60px)] overflow-y-auto">
                            {localPost.comments.length > 0 ? (
                                localPost.comments.map((comment, index) => (
                                    <div key={index} className="mb-3">
                                        <div className="flex items-start space-x-3">
                                            <img src={comment.user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${comment.user.avatar}` : defaultAvatar} alt="Profile" className="w-8 h-8 rounded-full" />
                                            <div>
                                                <p className="text-sm font-semibold">{comment.user.name}</p>
                                                <p className="text-sm">{comment.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-500">Комментариев пока нет</p>
                            )}
                        </div>

                        <div className="flex fixed bottom-0 left-0 right-0 p-2 bg-white dark:bg-neutral-700">
                            <input
                                type="text"
                                value={newComment}
                                onChange={onCommentChange}
                                className="flex-grow border border-gray-300 p-2 rounded-full focus:outline-none"
                                placeholder="Напишите комментарий..."
                            />
                            <button onClick={onSubmitComment} className="bg-violet-500 text-white px-3 py-2 rounded-full ml-2 hover:bg-violet-600">
                                <i className="fa-solid fa-paper-plane"></i>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90 w-full">
            <div className="relative w-full">
                <button onClick={prevImage} className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white text-2xl p-2">
                    <i className="fa-solid fa-chevron-left"></i>
                </button>
                <div className="relative max-w-3xl mx-auto">
                    <img src={`${process.env.REACT_APP_BASE_URL}/storage/${images[selectedIndex].image_path}`} alt="Full Size" className="w-full min-vh-100 max-h object-cover rounded shadow dark:bg-neutral-800" />
                </div>
                <button onClick={nextImage} className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white text-2xl p-2">
                    <i className="fa-solid fa-chevron-right"></i>
                </button>
            </div>
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-4 flex justify-between items-center text-white">
                <p>{selectedIndex + 1}/{images.length}</p>
                <p>{images[selectedIndex].name}</p>
            </div>
            <aside className="float-left w-1/3 h-screen bg-neutral-50 shadow z-50 p-6 dark:bg-neutral-800 relative">
                <button onClick={onClose} className="absolute top-0 right-0 text-white text-4xl p-6">
                    <i className="fa-solid fa-xmark text-2xl text-neutral-900 dark:text-white hover:text-violet-400 transition duration-300"></i>
                </button>
                <div className="flex items-center">
                    <img src={localPost.user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${localPost.user.avatar}` : defaultAvatar} alt="Profile" className="w-12 h-12 rounded-full" />
                    <div className="flex flex-col ml-4">
                        <p className="text-lg font-semibold dark:text-white">{localPost.user.name}</p>
                        <p className="text-gray-700 dark:text-neutral-300">
                            {format(new Date(localPost.created_at), 'dd MMMM, yyyy', { locale: ru })}
                        </p>
                    </div>
                </div>
                <div className="mt-4 border-b-2 pb-4 border-gray-300 dark:border-neutral-600">
                    <p className="text-gray-700 dark:text-white">{localPost.content}</p>
                </div>

                {/* Отображение комментариев */}
                <div className="flex flex-col h-[calc(100vh-260px)] overflow-y-auto">
                    <h2 className="text-xl font-semibold dark:text-white">Комментарии</h2>
                    <div className="mt-2 mb-28 pr-2">
                        {localPost.comments.length > 0 ? (
                            localPost.comments.map((comment, index) => (
                                <div key={index} className="p-2 border-b border-gray-300 dark:border-neutral-600">
                                    <div className="flex items-start space-x-4">
                                        <img src={comment.user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${comment.user.avatar}` : defaultAvatar} alt="Profile" className="w-10 h-10 rounded-full" />
                                        <div className="flex flex-col">
                                            <p className="text-gray-700 dark:text-white font-semibold">{comment.user.name}</p>
                                            <p className="text-gray-700 dark:text-neutral-300">{comment.content}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-500">Комментариев пока нет</p>
                        )}
                    </div>
                </div>

                <div className="absolute bottom-0 left-0 right-0 w-full bg-neutral-50 p-4 shadow z-50 dark:bg-neutral-800 flex">
                    <input
                        type="text"
                        value={newComment}
                        onChange={onCommentChange}
                        className="flex-grow border border-gray-300 p-2 rounded-full focus:outline-none focus:border-violet-500 dark:bg-neutral-700 dark:text-white dark:border-neutral-600"
                        placeholder="Комментарий..."
                    />
                    <button onClick={onSubmitComment} className="bg-violet-500 text-white px-2.5 py-1.5 w-10 h-10 rounded-full ml-2 hover:bg-violet-600 transition duration-200">
                        <i className="fa-solid fa-paper-plane"></i>
                    </button>
                </div>
            </aside>
        </div>
    );
}

export default Modal;
