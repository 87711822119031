import React from "react";
import { Link } from "react-router-dom";

export default function Settings() {
    return (
        <div className="container mx-auto p-4">
            <Link to="/" className="text-gray-700 hover:text-violet-400 transition duration-200 dark:text-white dark:hover:text-violet-400"><i className="fas fa-chevron-left mr-2"></i>Назад</Link>
            <h1 className="text-3xl font-extrabold mb-4 mt-4 dark:text-white">Настройки</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 mt-4">
                <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col justify-between dark:bg-neutral-800">
                    <iframe src="https://lottie.host/embed/8e1366d6-2366-4ef6-9994-3e3442c81c81/lFVf0xaI2S.json" className="w-full h-60" loading='eager'></iframe>
                    <div className="flex flex-col justify-between">
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 dark:text-white">Настройки аккаунта</h2>
                        <p className="text-gray-700 mb-4 dark:text-white">Здесь вы можете изменить вашу почту и пароль.</p>
                        <Link to="/settings/change-password"
                              className="text-gray-700 hover:text-violet-400 transition duration-200 dark:text-white dark:hover:text-violet-400">Изменить
                            данные<i className="fas fa-chevron-right ml-2 items-center"></i></Link>
                    </div>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col justify-between mt-4 md:mt-0 dark:bg-neutral-800">
                    <iframe src="https://lottie.host/embed/9fbf5235-a134-4029-a977-8e1f9d6530f9/91nixheEDS.json" className="w-full h-60" loading='eager'></iframe>
                    <div className="flex flex-col justify-between">
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 dark:text-white">Настройки аккаунта</h2>
                        <p className="text-gray-700 mb-4 dark:text-white">Здесь вы можете удалить аккаунт.</p>
                        <Link to="/settings/delete-account"
                              className="text-gray-700 hover:text-violet-400 transition duration-200 dark:text-white dark:hover:text-violet-400">Удалить аккаунт<i
                            className="fas fa-chevron-right ml-2 items-center"></i></Link>
                    </div>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col justify-between mt-4 md:mt-0 dark:bg-neutral-800">
                    <iframe src="https://lottie.host/embed/31d10123-094a-4013-9743-e8156e1697cf/ZuYE7HQSP7.json" className="w-full h-60" loading='eager'></iframe>
                    <div className="flex flex-col justify-between">
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 dark:text-white">Связаться с поддержкой</h2>
                        <p className="text-gray-700 mb-4 dark:text-white">Здесь вы можете связаться с поддержкой.</p>
                        <Link to="/settings/contact-support"
                              className="text-gray-700 hover:text-violet-400 transition duration-200 dark:text-white dark:hover:text-violet-400">Связаться с поддержкой<i
                            className="fas fa-chevron-right ml-2 items-center"></i></Link>
                    </div>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col justify-between mt-4 md:mt-0 dark:bg-neutral-800">
                    <iframe src="https://lottie.host/embed/c4b0a85e-c70e-4227-aa7a-6d389afb87a5/Df3GPigWPY.json" className="w-full h-60" loading='eager'></iframe>
                    <div className="flex flex-col justify-between">
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 dark:text-white">Report an issue</h2>
                        <p className="text-gray-700 mb-4 dark:text-white">Report an issue here.</p>
                        <Link to="/settings/report-issue"
                              className="text-gray-700 hover:text-violet-400 transition duration-200 dark:text-white dark:hover:text-violet-400">Report an issue<i
                            className="fas fa-chevron-right ml-2 items-center"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
