import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import useWindowSize from "../hooks/useWindowSize";

const DeletePost = ({ show, onClose, onDelete }) => {
    const { width } = useWindowSize();
    const modalRef = useRef(null);

    const handleTouchMove = (e) => {
        if (modalRef.current) {
            const touchY = e.touches[0].clientY;
            if (touchY > window.innerHeight * 0.9) {
                onClose();
            }
        }
    };

    if (!show) return null;

    const overlayVariants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 }
    };

    const mobileModalVariants = {
        open: { y: 0, opacity: 1 },
        closed: { y: '100%', opacity: 0 }
    };

    const desktopModalVariants = {
        open: { opacity: 1, scale: 1 },
        closed: { opacity: 0, scale: 0.9 }
    };

    return (
        <>
            {/* Оверлей для мобильной версии */}
            {width < 768 && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-30 z-40"
                    initial="hidden"
                    animate={show ? "visible" : "hidden"}
                    variants={overlayVariants}
                    transition={{ duration: 0.3 }}
                    onClick={onClose}
                ></motion.div>
            )}

            {/* Модальное окно для мобильной версии */}
            {width < 768 ? (
                <motion.div
                    ref={modalRef}
                    className={`fixed bottom-0 left-0 w-full bg-white shadow-lg rounded-t-2xl dark:bg-neutral-800 z-50`}
                    initial="closed"
                    animate={show ? "open" : "closed"}
                    variants={mobileModalVariants}
                    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                    onTouchMove={handleTouchMove}
                >
                    <div className="p-5">
                        <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4"></div>

                        <h2 className="text-lg font-medium mb-4">Подтверждение удаления</h2>
                        <p>Вы уверены, что хотите удалить этот пост?</p>
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={onClose}
                                className="bg-neutral-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-neutral-600 transition-colors duration-200"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={onDelete}
                                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors duration-200"
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </motion.div>
            ) : (
                // Модальное окно для десктопной версии
                <motion.div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-40"
                    initial="closed"
                    animate={show ? "open" : "closed"}
                    variants={overlayVariants}
                    transition={{ duration: 0.3 }}
                >
                    <motion.div
                        className="bg-white p-5 rounded-lg shadow-lg w-96 dark:bg-neutral-800"
                        variants={desktopModalVariants}
                        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                    >
                        <h2 className="text-lg font-medium mb-4">Подтверждение удаления</h2>
                        <p>Вы уверены, что хотите удалить этот пост?</p>
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={onClose}
                                className="bg-neutral-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-neutral-600 transition-colors duration-200"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={onDelete}
                                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors duration-200"
                            >
                                Удалить
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </>
    );
};

export default DeletePost;
