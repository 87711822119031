import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import axios from 'axios';

const BottomNav = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const location = useLocation();

    const fetchUnreadMessages = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages/unread_count`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUnreadMessagesCount(response.data.unread_count || 0);
        } catch (error) {
            console.error('Error fetching unread messages count', error);
        }
    };

    useEffect(() => {
        fetchUnreadMessages();
    }, []);

    useEffect(() => {
        const currentPath = location.pathname;
        const currentIndex = items.findIndex(item => item.to === currentPath);
        setActiveIndex(currentIndex);
    }, [location.pathname]);

    const items = [
        { icon: "home", to: "/" },
        { icon: "search", to: "/search" },
        { icon: "comment", to: "/messages", count: unreadMessagesCount },
        { icon: "bell", to: "/notifications" },
        { icon: "user", to: "/profile" },
    ];

    return (
        <div className="bg-white p-4 rounded-t-2xl shadow-lg fixed bottom-0 left-0 right-0 flex justify-center dark:bg-neutral-800 z-20">
            <div className="flex space-x-4">
                {items.map((item, index) => (
                    <div
                        key={item.icon}
                        className={`relative flex items-center justify-center w-16 h-12 rounded-full transition-all duration-300 cursor-pointer ${
                            activeIndex === index ? 'w-16 bg-gray-200 dark:bg-neutral-700' : 'bg-transparent'
                        }`}
                        onClick={() => setActiveIndex(index)}
                    >
                        <Link to={item.to} className="flex items-center justify-center relative">
                            <span className="text-xl relative">
                                <i
                                    className={`transition-all duration-300 fas fa-${item.icon} ${
                                        activeIndex === index ? 'text-violet-500' : 'text-gray-500 dark:text-gray-400'
                                    }`}
                                ></i>
                                {item.count > 0 && (
                                    <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
                                        {item.count}
                                    </span>
                                )}
                            </span>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BottomNav;
