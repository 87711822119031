import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import {Link, useNavigate} from 'react-router-dom';
import Modal from 'react-modal';
import Echo from '../../echo'; // Импортируйте echo.js

function FriendListForProfile() {
  const [friends, setFriends] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [friendToDelete, setFriendToDelete] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const fetchFriends = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/friends`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFriends(response.data);
    } catch (error) {
      console.error('There was an error fetching the friends!', error);
    }
  };

  const openModal = (friendId) => {
    setFriendToDelete(friendId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFriendToDelete(null);
  };

  const handleDeleteFriend = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/friends/${friendToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFriends(friends.filter(friend => friend.friend.id !== friendToDelete));
      closeModal();
    } catch (error) {
      console.error('There was an error deleting the friend!', error);
    }
  };

  const handleViewProfile = (friendId) => {
    navigate(`/profile/${friendId}`);
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchFriends();

      window.Echo.join('users')
          .here(users => {
            setFriends(friends => friends.map(friend => {
              const isOnline = users.find(user => user.id === friend.friend.id);
              return { ...friend, is_online: !!isOnline };
            }));
          })
          .joining(user => {
            setFriends(friends => friends.map(friend => {
              if (friend.friend.id === user.id) {
                return { ...friend, is_online: true };
              }
              return friend;
            }));
          })
          .leaving(user => {
            setFriends(friends => friends.map(friend => {
              if (friend.friend.id === user.id) {
                return { ...friend, is_online: false };
              }
              return friend;
            }));
          });
    }
  }, [isLoggedIn]);

  return (
      <div className="mx-auto justify-center w-full max-w-3xl p-4 bg-white dark:bg-neutral-900 rounded-lg shadow">
        <Link to="/friends" className="text-lg font-medium text-gray-800 dark:text-white mb-4 block text-center">Друзья</Link>
        <ul className="flex space-x-6 overflow-x-auto mb-4 -ml-2">
          {friends.length === 0 ? (
              <p className="text-gray-500 dark:text-gray-400">У вас нет друзей</p>
          ) : (
              friends.map(friend => (
                  <li key={friend.id} className="flex flex-col items-center">
                    <img
                        src={friend.friend.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${friend.friend.avatar}` : "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"}
                        alt={friend.friend.name}
                        className="w-12 h-12 rounded-full object-cover mb-2"
                    />
                    <p className="text-sm w-16 font-medium text-gray-800 dark:text-white">{friend.friend.name}</p>
                  </li>
              ))
          )}
        </ul>
      </div>
  );
}

export default FriendListForProfile;
