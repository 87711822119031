import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

const ForwardMessageModal = ({ isVisible, onClose, onForward, onForwardAll, isMultiple }) => {
    const [chats, setChats] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isVisible) {
            const fetchChats = async () => {
                setLoading(true);
                setError(null);
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/chats`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    setChats(response.data);
                } catch (error) {
                    console.error('There was an error fetching the chats!', error);
                    setError('Не удалось загрузить чаты.');
                } finally {
                    setLoading(false);
                }
            };
            fetchChats();
        }
    }, [isVisible]);

    const getChatPartnerName = (chat) => {
        const userId = parseInt(localStorage.getItem('user_id'), 10);
        return chat.user1_id === userId ? chat.user2.name : chat.user1.name;
    };

    const getChatPartnerAvatar = (chat) => {
        const userId = parseInt(localStorage.getItem('user_id'), 10);
        const avatarPath = chat.user1_id === userId ? chat.user2.avatar : chat.user1.avatar;

        if (avatarPath) {
            return `${process.env.REACT_APP_BASE_URL}/storage/${avatarPath}`;
        }

        return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s';
    };

    const handleForward = () => {
        if (selectedChatId) {
            if (isMultiple) {
                // Пересылаем несколько сообщений
                onForwardAll(selectedChatId);
            } else {
                // Пересылаем одно сообщение
                onForward(selectedChatId);
            }
        }
    };

    if (!isVisible) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <motion.div
                initial={{ opacity: 0, scale: 0.85 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.85 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="bg-white dark:bg-neutral-800 rounded-2xl p-6 w-[90%] max-w-md shadow-xl"
            >
                <h2 className="text-xl font-bold text-center mb-6 text-neutral-900 dark:text-white">
                    Выберите чат для пересылки
                </h2>

                {loading ? (
                    <div className="text-center">Загрузка чатов...</div>
                ) : error ? (
                    <div className="text-center text-red-500">{error}</div>
                ) : chats.length === 0 ? (
                    <div className="text-center">Нет доступных чатов для пересылки.</div>
                ) : (
                    <ul className="space-y-4 max-h-64 overflow-y-auto custom-scrollbar">
                        <AnimatePresence>
                            {chats.map((chat) => (
                                <motion.li
                                    key={chat.id}
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -10 }}
                                    transition={{ duration: 0.2, ease: "easeInOut" }}
                                    className={`flex items-center space-x-4 p-3 rounded-lg cursor-pointer transition-all duration-300 ${
                                        selectedChatId === chat.id
                                            ? 'bg-violet-500 text-white shadow-lg'
                                            : 'bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-600'
                                    }`}
                                    onClick={() => setSelectedChatId(chat.id)}
                                >
                                    <img
                                        src={getChatPartnerAvatar(chat)}
                                        alt={getChatPartnerName(chat)}
                                        className="w-12 h-12 rounded-full object-cover border-2 border-white shadow-sm"
                                    />
                                    <span className="font-medium text-lg">{getChatPartnerName(chat)}</span>
                                </motion.li>
                            ))}
                        </AnimatePresence>
                    </ul>
                )}

                <div className="mt-6 flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 rounded-lg bg-red-500 text-white hover:bg-red-600 transition-all duration-200"
                    >
                        Отмена
                    </button>
                    <button
                        onClick={handleForward}
                        disabled={!selectedChatId}
                        className={`px-4 py-2 rounded-lg transition-all duration-200 ${
                            selectedChatId
                                ? 'bg-violet-500 text-white hover:bg-violet-600'
                                : 'bg-violet-300 text-white cursor-not-allowed'
                        }`}
                    >
                        Переслать
                    </button>
                </div>
            </motion.div>
        </div>
    );
};

export default ForwardMessageModal;
