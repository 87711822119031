import React from 'react';

function ConfirmationModal({ onConfirm, onCancel }) {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
            <div className="bg-white dark:bg-neutral-800 rounded-lg p-4 w-[80%] max-w-sm">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-4">Удалить чат?</h2>
                <p className="text-gray-600 dark:text-gray-300 mb-6">
                    Вы уверены, что хотите удалить этот чат? Это действие необратимо.
                </p>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 bg-gray-300 hover:bg-gray-400 dark:bg-neutral-700 dark:hover:bg-neutral-600 rounded-lg text-black dark:text-white"
                    >
                        Отмена
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg"
                    >
                        Удалить
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationModal;
