import React from 'react';

const wallpapers = [
    '/bg/gr1962_1920_1080.png',
    '/bg/gr1951_1920_1080.png',
    '/bg/gr1923_1920_1080.png',
    '/bg/gr1922_1920_1080.png',
    '/bg/gr1915_1920_1080.png',
    '/bg/gr1943_1920_1080.png',
    '/bg/gr1877_1920_1080.png',
    '/bg/gr1755_1920_1080.png',
    '/bg/gr1822_1920_1080.png',
    '/bg/gr1245_1920_1080.png',
];

function WallpaperModal({ onClose, onChange }) {
    const handleWallpaperClick = (wallpaper) => {
        onChange(wallpaper);
        onClose();
    };

    const resetToDefault = () => {
        onChange(null);
        onClose();
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
            <div className="bg-white dark:bg-neutral-800 rounded-lg p-4 w-[80%] max-w-lg">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-4">Выберите обои</h2>
                <div className="grid grid-cols-2 gap-4">
                    {wallpapers.map((wallpaper, index) => (
                        <img
                            key={index}
                            src={wallpaper}
                            alt={`Wallpaper ${index + 1}`}
                            className="w-full h-full cursor-pointer rounded-lg shadow-lg hover:opacity-75 transition duration-200"
                            onClick={() => handleWallpaperClick(wallpaper)}
                        />
                    ))}
                </div>
                <div className="flex space-x-2 mt-4">
                <button
                    onClick={resetToDefault}
                    className="mt-4 px-4 py-2 bg-red-500 hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700 text-white rounded-lg dark:text-white transition duration-300"
                >
                    Сбросить обои
                </button>
                <button
                    onClick={onClose}
                    className="mt-4 px-4 py-2 bg-gray-300 hover:bg-gray-400 dark:bg-neutral-700 dark:hover:bg-neutral-600 rounded-lg text-black dark:text-white transition duration-300"
                >
                    Отмена
                </button>
                </div>
            </div>
        </div>
    );
}

export default WallpaperModal;
