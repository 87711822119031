import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import toast from 'react-hot-toast';

const CustomModal = ({ message, onConfirm, onCancel }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white dark:bg-neutral-800 rounded-lg shadow-lg p-6 w-11/12 max-w-md mx-auto">
            <h2 className="text-lg font-bold mb-4 dark:text-white">Подтверждение</h2>
            <p className="text-gray-800 dark:text-gray-300 mb-6">{message}</p>
            <div className="flex justify-end space-x-4">
                <button
                    onClick={onCancel}
                    className="bg-gray-200 hover:bg-gray-300 text-gray-800 px-4 py-2 rounded dark:bg-neutral-600 dark:hover:bg-neutral-500 dark:text-white"
                >
                    Отмена
                </button>
                <button
                    onClick={onConfirm}
                    className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded"
                >
                    Удалить
                </button>
            </div>
        </div>
    </div>
);

export default function DeleteAccount() {
    const { logout, isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDeleteAccount = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            toast.error('Не удалось найти токен. Пожалуйста, войдите снова.');
            return;
        }

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/delete-account`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            toast.success('Аккаунт успешно удален');
            logout();
            navigate('/');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('Неавторизованный доступ! Пожалуйста, войдите в систему.');
            } else {
                toast.error('Произошла ошибка при удалении аккаунта!');
            }
            console.error('Ошибка при удалении аккаунта:', error);
        }
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="container mx-auto p-4 h-screen flex flex-col">
            <Link to='/settings' className="text-gray-700 mb-4 hover:text-violet-500 transition duration-200 dark:text-white dark:hover:text-violet-500">
                <i className="fas fa-chevron-left mr-2"></i>Назад
            </Link>
            {isLoggedIn ? (
                <>
                    <h1 className="text-3xl font-extrabold mb-4 dark:text-white">Удаление аккаунта</h1>
                    <p className="text-lg text-gray-800 mb-4 dark:text-white">
                        Вы уверены, что хотите удалить свой аккаунт? Это действие необратимо.
                    </p>
                    <button
                        onClick={openModal}
                        className="w-fit bg-neutral-50 border-2 border-red-600 text-red-600 px-4 py-2 rounded-full hover:bg-red-600 hover:text-neutral-50 transition duration-300 dark:bg-neutral-800 dark:border-red-500 dark:text-red-500 dark:hover:bg-red-500 dark:hover:text-white"
                    >
                        Удалить аккаунт
                    </button>
                    {isModalOpen && (
                        <CustomModal
                            message="Вы уверены, что хотите удалить свой аккаунт? Это действие необратимо."
                            onConfirm={() => {
                                closeModal();
                                handleDeleteAccount();
                            }}
                            onCancel={closeModal}
                        />
                    )}
                </>
            ) : (
                <div className="container mx-auto p-4 ">
                    <h1 className="text-3xl font-extrabold mb-4 mt-4">Удаление аккаунта</h1>
                    <p className="text-lg text-gray-800 mb-4">Войдите в свой аккаунт, чтобы удалить его.</p>
                </div>
            )}
        </div>
    );
}
