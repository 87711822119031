import React from "react";
import {Link} from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";

const Notifications = () => {

    const { width } = useWindowSize();
    return (
        <div className="container mx-auto p-4">
            <Link to="/" className="text-gray-700 mb-4 hover:text-violet-400 transition duration-200 dark:text-white dark:hover:text-violet-400">
                <i className="fas fa-chevron-left mr-2"></i>Назад
            </Link>
            <h1 className="text-3xl font-extrabold mb-4 mt-4 dark:text-white">Уведомления</h1>
            <p className="text-lg dark:text-white">Скоро здесь все появится!</p>

            {width < 768 && (
            <BottomNav />
            )}
        </div>
    )
}

export default Notifications;