import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import toast from 'react-hot-toast';

const notifyError = (message) => toast.error(message);
const notifySuccess = (message) => toast.success(message);

function AddFriend({ fetchFriends }) {
  const [friendName, setFriendName] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (friendName.length > 1) {
      fetchSuggestions(friendName);
    } else {
      setSuggestions([]);
      setDropdownVisible(false);
    }
  }, [friendName]);

  const fetchSuggestions = async (query) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/friends/suggestions`, {
        params: { query },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSuggestions(response.data);
      setDropdownVisible(true);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleSuggestionClick = (name) => {
    setFriendName(name);
    setDropdownVisible(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/friends`, {
        friend_name: friendName,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      notifySuccess('Заявка отправлена!');
      setFriendName('');
      fetchFriends();
    } catch (error) {
      if (error.response) {
        notifyError(error.response.data.error || 'Произошла ошибка при отправке заявки!');
      } else if (error.request) {
        notifyError('Нет ответа от сервера.');
      } else {
        notifyError('Произошла ошибка при отправке заявки.');
      }
    }
  };

  if (!isLoggedIn) {
    return <p className="text-center text-red-500 mt-4">Please log in to add friends.</p>;
  }

  return (
      <div className="container mx-auto p-6 bg-white dark:bg-neutral-800 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">Добавить друга</h2>
        <form onSubmit={handleSubmit} className="flex flex-col space-y-3">
          <div className="relative">
            <input
                id="friendName"
                type="text"
                value={friendName}
                onChange={(e) => setFriendName(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-violet-500 dark:bg-neutral-700 dark:border-gray-600 dark:text-gray-300"
                placeholder="Введите имя друга"
            />
            {isDropdownVisible && suggestions.length > 0 && (
                <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg shadow-lg dark:bg-neutral-700 dark:border-gray-600 dark:text-gray-300 mt-2">
                  {suggestions.map((suggestion) => (
                      <li
                          key={suggestion.id}
                          onClick={() => handleSuggestionClick(suggestion.name)}
                          className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-neutral-600 rounded-lg transition-colors"
                      >
                        <img
                            src={suggestion.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${suggestion.avatar}` : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s'}
                            alt={suggestion.name}
                            className="w-12 h-12 rounded-full object-cover mr-3"
                        />
                        <span>{suggestion.name}</span>
                      </li>
                  ))}
                </ul>
            )}
          </div>
          <button
              type="submit"
              className="bg-violet-600 text-white px-4 py-2 rounded-lg hover:bg-violet-700 transition-colors duration-300"
          >
            Добавить
          </button>
        </form>
      </div>
  );
}

export default AddFriend;
