import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import useWindowSize from "../hooks/useWindowSize";


function Header() {
  const { isLoggedIn, user, logout } = useContext(AuthContext);
  const [userData, setUserData] = useState(user);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { width } = useWindowSize();

  useEffect(() => {
    const fetchUserData = async () => {
      if (isLoggedIn && !userData) {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setUserData(response.data);
        } catch (error) {
          console.error('Ошибка при загрузке данных пользователя!', error);
        }
      }
    };

    fetchUserData();
  }, [isLoggedIn, userData]);

  const handleLogout = () => {
    logout();
    setUserData(null);
    setDropdownVisible(false);
    navigate('/login');
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const changeTheme = () => {
    document.documentElement.classList.toggle('dark');
    const currentTheme = document.documentElement.classList.contains('dark') ? 'dark' : 'light';
    localStorage.setItem('theme', currentTheme);
    setDropdownVisible(false);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownVisible]);

  const defaultAvatar = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s';

  return (
      <header className="bg-white dark:bg-neutral-800 shadow-sm border-b border-gray-200 dark:border-neutral-800">
        <div className="container mx-auto flex items-center justify-between py-3 px-4 md:px-6">
          <div className="flex items-center">
            <Link to="/" className="text-xl font-semibold text-gray-800 dark:text-white">
              chattify
            </Link>
            <div className="relative ml-2 group">
            <span className="text-pink-500 font-semibold text-xs border-2 border-pink-500 px-2 py-0.5 rounded-full cursor-pointer">Beta</span>
              <div className="absolute bottom--6 left-1/2 transform -translate-x-1/2 mb-2 w-max bg-neutral-700 text-white text-xs rounded-lg py-1 px-3 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <p>Приложение в стадии активной разработки!</p>
                <p>Могут возникать непредвиденные ошибки</p>
              </div>
            </div>
          </div>
          <nav className="flex items-center space-x-4 md:space-x-6">
            {!isLoggedIn && (
                <>
                  <Link to="/login"
                        className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition text-sm md:text-base">
                    Войти
                  </Link>
                  <Link to="/register"
                        className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition text-sm md:text-base">
                    Регистрация
                  </Link>
                </>
            )}
            {width < 768 && (
                <Link to="/notifications" className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition">
                  <i className="fas fa-bell text-gray-600 dark:text-gray-400 ml-2"></i>
                </Link>
            )}
            {isLoggedIn && (
                <div className="relative" ref={dropdownRef}>
                  {userData && (
                      <div
                          className="flex items-center space-x-2 cursor-pointer"
                          onClick={toggleDropdown}
                      >
                        <img
                            src={userData.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${userData.avatar}` : defaultAvatar}
                            alt="Avatar"
                            className="w-8 h-8 rounded-full object-cover border border-gray-300 dark:border-gray-600"
                        />
                        <p className="hidden md:block text-gray-800 dark:text-white font-medium text-sm md:text-base">
                          {userData.name}
                        </p>
                        <i className={`fas fa-chevron-down text-gray-600 dark:text-gray-400 transition-transform duration-200 ${dropdownVisible ? 'rotate-180' : ''}`}></i>
                      </div>
                  )}
                  {dropdownVisible && (
                      <div
                          className="absolute right-0 mt-2 w-48 bg-white dark:bg-neutral-800 text-gray-800 dark:text-gray-200 rounded-lg shadow-lg overflow-hidden z-50">
                        <Link
                            to="/profile"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-neutral-700 transition"
                            onClick={closeDropdown}
                        >
                          <i className="fa-regular fa-user mr-2"></i>Профиль
                        </Link>
                        {width < 768 && (
                            <Link
                                to="/settings"
                                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-neutral-700 transition"
                                onClick={closeDropdown}
                            >
                              <i className="fas fa-cog mr-2"></i>Настройки
                            </Link>
                        )}
                        <button
                            onClick={changeTheme}
                            className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-neutral-700 transition"
                        >
                          <i className="fas fa-palette mr-2"></i>Сменить тему
                        </button>
                        <button
                            onClick={handleLogout}
                            className="block w-full text-left px-4 py-2 text-red-500 hover:bg-gray-100 dark:hover:bg-neutral-700 transition"
                        >
                          <i className="fas fa-sign-out-alt mr-2"></i>Выйти
                        </button>
                      </div>
                  )}
                </div>
            )}
          </nav>
        </div>
      </header>
  );
}

export default Header;
