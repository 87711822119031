import React from 'react';
import { Link } from 'react-router-dom';

function ProfileHeader({ isLoggedIn, handleLogout }) {
    return (
        <header className="flex justify-between items-center py-4 border-b border-neutral-300 dark:border-neutral-700">
            <Link to="/" className="text-gray-600 hover:text-violet-600 dark:text-gray-300 dark:hover:text-violet-400 transition">
                <i className="fas fa-chevron-left mr-2"></i>Назад
            </Link>
            {isLoggedIn && (
                <button onClick={handleLogout} className="text-red-500 hover:text-red-600 transition">
                    <i className="fas fa-sign-out-alt mr-2"></i>Выйти
                </button>
            )}
        </header>
    );
}

export default ProfileHeader;
