import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { AuthContext } from '../context/AuthContext';
import CreateChat from './CreateChat';
import Chat from './Chat';
import { motion, AnimatePresence } from 'framer-motion';
import Pusher from 'pusher-js';
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";

function ChatList() {
    const [chats, setChats] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [isClosing, setIsClosing] = useState(false);
    const {isLoggedIn} = useContext(AuthContext);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        chatId: null,
        position: { x: 0, y: 0 },
    });
    const contextMenuRef = useRef(null);
    const { width } = useWindowSize();
    const [touchStartTime, setTouchStartTime] = useState(0);

    const handleTouchStart = useCallback((event, chatId) => {
        setTouchStartTime(Date.now());
    }, []);

    const handleTouchEnd = useCallback((event, chatId) => {
        if (Date.now() - touchStartTime >= 500) { // Если удержание дольше 500ms
            const x = event.changedTouches[0].clientX;
            const y = event.changedTouches[0].clientY;

            setContextMenu({
                visible: true,
                chatId: chatId,
                position: { x, y },
            });
        }
    }, [touchStartTime]);


    const fetchChats = async (isInitial = false) => {
        if (isInitial) {
            setIsInitialLoading(true);
        } else {
            setIsRefreshing(true);
        }

        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/chats`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setChats(response.data);
        } catch (error) {
            console.error('There was an error fetching the chats!', error);
        } finally {
            if (isInitial) {
                setIsInitialLoading(false);
            } else {
                setIsRefreshing(false);
            }
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchChats(true);
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, { cluster: 'eu' });
            const channel = pusher.subscribe('chat-channel');
            channel.bind('message-sent', () => fetchChats(false));
            const interval = setInterval(() => fetchChats(false), 1000);
            return () => {
                clearInterval(interval);
                pusher.unsubscribe('chat-channel');
            };
        }
    }, [isLoggedIn]);

    const handlePinChat = async (chatId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/pin-chat/${chatId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const { is_pinned } = response.data;

            setChats((prevChats) =>
                prevChats.map((chat) =>
                    chat.id === chatId ? { ...chat, is_pinned } : chat
                )
            );
        } catch (error) {
            console.error('Ошибка при закреплении чата:', error);
        }
    };

    const sortedChats = chats
        .slice()
        .sort((a, b) => {
            if (a.is_pinned && !b.is_pinned) return -1;
            if (!a.is_pinned && b.is_pinned) return 1;

            const aLastMessageTime = a.last_message ? new Date(a.last_message.created_at) : null;
            const bLastMessageTime = b.last_message ? new Date(b.last_message.created_at) : null;
            return bLastMessageTime - aLastMessageTime;
        });

    const handleSendMessage = async (message) => {
        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();

            formData.append('content', message.content);
            if (message.attachment) {
                formData.append('attachment', message.attachment);
            }

            await axios.post(`${process.env.REACT_APP_API_URL}/messages`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            fetchChats(false);
        } catch (error) {
            console.error('Ошибка при отправке сообщения:', error);
        }
    };

    const handleDeleteChat = async (chatId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${process.env.REACT_APP_API_URL}/chats/${chatId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchChats(false);
        } catch (error) {
            console.error('Error deleting chat:', error);
        }
    };

    const handleContextMenu = useCallback((event, chatId) => {
        event.preventDefault();
        const menuWidth = 200;
        const menuHeight = 150;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        let x = event.clientX;
        let y = event.clientY;

        if (x + menuWidth > screenWidth) {
            x = screenWidth - menuWidth;
        }

        if (y + menuHeight > screenHeight) {
            y = screenHeight - menuHeight;
        }

        setContextMenu({
            visible: true,
            chatId: chatId,
            position: { x, y },
        });
    }, []);

    const handleContextMenuAction = async (action) => {
        if (action === 'mark-as-read') {
            await handleMarkAsRead(contextMenu.chatId);
        } else if (action === 'mark-as-unread') {
            await handleMarkAsUnread(contextMenu.chatId);
        } else if (action === 'pin') {
            await handlePinChat(contextMenu.chatId);
        } else if (action === 'delete') {
            await handleDeleteChat(contextMenu.chatId);
        }
        handleCloseContextMenu();
    };

    const handleCloseContextMenu = () => {
        setContextMenu({
            visible: false,
            chatId: null,
            position: { x: 0, y: 0 },
        });
    };
    const handleMarkAsUnread = async (chatId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `${process.env.REACT_APP_API_URL}/chats/mark-as-unread/${chatId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            await fetchChats(false); // Обновите состояние чатов
        } catch (error) {
            console.error('Ошибка при изменении статуса чата:', error);
        }
    };

    const handleMarkAsRead = async (chatId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `${process.env.REACT_APP_API_URL}/chats/mark-as-read/${chatId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            fetchChats(false);
        } catch (error) {
            console.error('Ошибка при изменении статуса чата:', error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenu.visible && contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                handleCloseContextMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [contextMenu.visible]);

    const getChatPartnerName = (chat) => {
        const userId = parseInt(localStorage.getItem('user_id'), 10);
        if (chat.user1_id === userId) {
            return chat.user2.name;
        } else {
            return chat.user1.name;
        }
    };

    const getLastMessage = (chat) => {
        const maxLength = 35;

        if (!chat.last_message) {
            return 'Сообщений нет.';
        }

        if (chat.last_message.is_image) {
            return 'Фотография';
        }

        const message = chat.last_message.content;

        if (!message) {
            return 'Сообщение отсутствует';
        }

        if (message.length <= maxLength) {
            return message;
        }

        if (message.length > maxLength) {
            return `${message.substring(0, maxLength)}...`;
        } else {
            return message;
        }
    };

    const getLastMessageTime = (chat) => {
        if (!chat.last_message) {
            return null;
        }
        return new Date(chat.last_message.created_at).toLocaleDateString('ru-RU', {
            month: 'long',
            day: 'numeric',
        }).slice(0, 6);
    };

    const defaultAvatar =
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s';

    if (isInitialLoading) {
        return (
            <div className={`container mx-auto flex h-3/4 dark:bg-neutral-900 ${width < 768 ? 'bg-white dark:bg-neutral-800 h-full w-full fixed top-0 inset-x-0 z-50 mt-0' : ''}`}>
                <div className="p-4 w-full lg:w-1/3 h-[calc(100vh-100px)] overflow-y-auto mt-4 rounded-lg dark:bg-neutral-800 mb-4">
                    <Link
                        to="/"
                        className={`cursor-pointer text-gray-700 hover:text-violet-500 transition duration-200 dark:text-white hover:dark:text-violet-400 ${width < 768 ? 'hidden' : ''}`}                    >
                        <i className="fas fa-chevron-left mr-2 mb-4"></i>Назад
                    </Link>
                    <h2 className="text-3xl font-extrabold text-gray-800 mb-5 dark:text-white">
                        Чаты
                    </h2>
                    <div className="flex justify-center items-center h-[calc(100vh-300px)]">
                        <ClipLoader color="#6B46C1" size={75} />
                    </div>
                </div>
                {width < 768 && (
                    <BottomNav />
                )}
            </div>
        );
    }

    if (chats.length === 0) {
        return (
            <div className="container mx-auto flex h-3/4 dark:bg-neutral-900">
                <div className="p-4 w-full lg:w-1/3 h-[calc(100vh-100px)] overflow-y-auto mt-4 rounded-lg dark:bg-neutral-800 mb-4">
                    <Link
                        to="/"
                        className={`cursor-pointer text-gray-700 hover:text-violet-500 transition duration-200 dark:text-white hover:dark:text-violet-400 ${width < 768 ? 'hidden' : ''}`}                    >
                        <i className="fas fa-chevron-left mr-2 mb-4"></i>Назад
                    </Link>
                    <h2 className="text-3xl font-extrabold text-gray-800 mb-5 dark:text-white">
                        Чаты
                    </h2>
                    <p className="text-gray-700 dark:text-white">У вас нет чатов.</p>
                    <p className="flex justify-between items-center bg-violet-500 hover:bg-violet-600 transition duration-200 text-white font-bold py-2 px-4 rounded mt-4">
                        Создайте чат, чтобы начать общение. <CreateChat />
                    </p>
                </div>
                {width < 768 && (
                    <BottomNav />
                )}
            </div>
        );
    }

    return (
        <div className={`container mx-auto flex flex-col lg:flex-row h-3/4 dark:bg-neutral-900 ${width < 768 ? 'bg-white h-full w-full fixed top-0 inset-x-0 z-50 mt-0' : ''}`}>
            <div className={`p-4 ${selectedChatId ? 'hidden lg:block' : 'block'} lg:w-1/3 h-[calc(100vh-100px)] overflow-y-auto mt-4 rounded-lg dark:bg-neutral-800 mb-4 relative ${width < 768 ? 'mt-0 w-full h-[calc(100vh-85px)]' : ''}`}>
                <Link
                    to="/"
                    className={`cursor-pointer text-gray-700 hover:text-violet-500 transition duration-200 dark:text-white hover:dark:text-violet-400 ${width < 768 ? 'hidden' : ''}`}
                >
                    <i className="fas fa-chevron-left mr-2 mb-4"></i>Назад
                </Link>
                <div className="flex items-center justify-between">
                    <h2 className="text-3xl font-extrabold text-gray-800 mb-5 dark:text-white">
                        Чаты
                    </h2>
                    <span className="mb-4"><CreateChat /></span>
                </div>
                {contextMenu.visible && (
                    <div className="fixed z-50 inset-0 overflow-y-auto">
                        <motion.div
                            className="context-menu w-auto absolute z-50 p-2 bg-white shadow-lg rounded-lg dark:bg-neutral-800"
                            style={{top: `${contextMenu.position.y}px`, left: `${contextMenu.position.x}px`}}
                            ref={contextMenuRef}
                            initial={{opacity: 0, scale: 0.9}}
                            animate={{opacity: 1, scale: 1}}
                            exit={{opacity: 0, scale: 0.9, transition: {duration: 0.3}}}
                        >
                            <button
                                onClick={() => {
                                    const chat = chats.find(chat => chat.id === contextMenu.chatId);
                                    const action = chat?.is_read ? 'mark-as-unread' : 'mark-as-read';
                                    handleContextMenuAction(action);
                                }}
                                className="flex items-center w-full px-4 py-2 text-sm hover:bg-gray-100 hover:rounded-lg dark:text-white dark:hover:bg-neutral-700 focus:outline-none"
                            >
                                <i className="fa-regular fa-comment mr-4"></i>
                                {chats.find(chat => chat.id === contextMenu.chatId)?.is_read ? 'Отметить как непрочитанное' : 'Отметить как прочитанное'}
                            </button>
                            <button
                                onClick={() => {
                                    handlePinChat(contextMenu.chatId);
                                    handleCloseContextMenu();
                                }}
                                className="flex items-center w-full px-4 py-2 text-sm hover:bg-gray-100 hover:rounded-lg dark:text-white dark:hover:bg-neutral-700 focus:outline-none"
                            >
                                <i className="fas fa-thumbtack mr-4"></i>
                                {chats.find(chat => chat.id === contextMenu.chatId)?.is_pinned ? 'Открепить' : 'Закрепить'}
                            </button>
                            <button
                                onClick={() => {
                                    handleDeleteChat(contextMenu.chatId);
                                    handleCloseContextMenu();
                                }}
                                className="flex items-center w-full px-4 py-2 text-sm text-red-500 hover:bg-gray-100 hover:rounded-lg dark:text-red-400 dark:hover:bg-neutral-700 focus:outline-none"
                            >
                                <i className="fas fa-trash mr-4"></i>Удалить
                            </button>
                        </motion.div>
                    </div>
                )}

                <ul className="space-y-4">
                    {sortedChats.map((chat) => (
                        <li key={chat.id} className="relative shadow-lg rounded-xl">
                            <button
                                onClick={() => setSelectedChatId(chat.id)}
                                onContextMenu={(event) => handleContextMenu(event, chat.id)}
                                className={`w-full text-left relative rounded-xl overflow-hidden transition-transform transform ${
                                    selectedChatId === chat.id
                                        ? 'bg-violet-50 dark:bg-violet-700'
                                        : 'bg-white dark:bg-neutral-700'
                                }`}
                                onTouchStart={(event) => handleTouchStart(event, chat.id)}
                                onTouchEnd={(event) => handleTouchEnd(event, chat.id)}
                            >
                                <div
                                    className={`absolute inset-0 transition-transform duration-500 ${
                                        selectedChatId === chat.id
                                            ? 'bg-violet-200 dark:bg-violet-600 scale-x-100'
                                            : 'bg-transparent scale-x-0'
                                    }`}
                                    style={{ zIndex: -1 }}
                                />
                                <motion.div
                                    className="flex p-4 rounded-lg"
                                    initial={{ opacity: 0, scale: 0.95 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.95 }}
                                    transition={{ type: 'spring', stiffness: 200, damping: 20 }}
                                >
                                    <img
                                        src={
                                            chat.user1_id === parseInt(localStorage.getItem('user_id'), 10)
                                                ? chat.user2.avatar
                                                    ? `${process.env.REACT_APP_BASE_URL}/storage/${chat.user2.avatar}`
                                                    : defaultAvatar
                                                : chat.user1.avatar
                                                    ? `${process.env.REACT_APP_BASE_URL}/storage/${chat.user1.avatar}`
                                                    : defaultAvatar
                                        }
                                        alt=""
                                        className="w-12 h-12 rounded-full object-cover mr-4 border border-gray-300 dark:border-gray-600"
                                    />
                                    <div className="flex-1">
                                        <div className="flex items-center">
                                            <p className={`text-xl font-semibold text-gray-700 dark:text-white ${width < 768 ? 'text-[clamp(16px,1.5vw,20px)]' : ''}`}>
                                                {getChatPartnerName(chat)}
                                            </p>
                                        </div>
                                        <p className="text-gray-500 dark:text-gray-300">
                                            {getLastMessage(chat)}
                                        </p>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-sm h-fit text-gray-500 dark:text-gray-300 items-center flex justify-center w-fit ml-auto mt-2">
                                            {chat.is_pinned ? <i className="fas fa-thumbtack mr-2 text-violet-500 dark:text-violet-300 rotate-45"></i> : ''}
                                            {getLastMessageTime(chat)}
                                        </p>
                                        {chat.unread_count > 0 && (
                                            <span className="text-white bg-violet-500 rounded-full text-sm w-5 h-5 flex items-center justify-center ml-6 mr-auto text-center mt-auto mb-auto">
                                                {chat.unread_count}
                                            </span>
                                        )}
                                    </div>
                                </motion.div>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={`p-4 ${selectedChatId ? 'block' : 'hidden lg:block'} lg:w-2/3`}>
                <AnimatePresence mode="wait">
                    {!isClosing && selectedChatId && (
                        <motion.div
                            key={selectedChatId}
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 100 }}
                            transition={{ type: 'spring', stiffness: 200, damping: 20 }}
                        >
                            <Chat
                                chatId={selectedChatId}
                                onNewMessage={handleSendMessage}
                                onCloseChat={() => setSelectedChatId(null)}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            {!selectedChatId && width < 768 && <BottomNav className="lg:hidden" />}
        </div>
    );
}

export default ChatList;
