import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PostList from '../PostList';

function ProfilePosts({ userId }) {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                    params: {
                        userId
                    }
                });
                setPosts(response.data);
            } catch (error) {
                console.error('There was an error fetching the posts!', error);
            }
        };
        fetchPosts();
    }, [userId]);

    return (
        <div className="w-full  mx-auto p-4 bg-white dark:bg-neutral-900 rounded-lg shadow-md">
            <h1 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-6">
                Посты
            </h1>
            <PostList posts={posts} setPosts={setPosts} userId={userId === localStorage.getItem('user_id') ? null : userId} />
        </div>
    );
}

export default ProfilePosts;
