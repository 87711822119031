import React from "react";
import { Link } from "react-router-dom";

export default function Support() {
    return (
        <div className="container mx-auto p-4 grid">
            <Link to="/settings" className="text-gray-700 hover:text-violet-400 transition duration-200"><i className="fas fa-chevron-left mr-2"></i>Назад</Link>
            <h1 className="text-3xl font-extrabold mb-4">Поддержка</h1>
            <h2 className="text-xl font-bold text-gray-800 mb-4">Скоро здесь все появится</h2>
        </div>
    );
}

